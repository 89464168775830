body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Ubuntu'; /*reference name*/
  src: url('./fonts/Ubuntu-Regular.ttf'); /*URL to font*/
  font-display: block; /*briefly hide text until the custom font is loaded*/
}

@font-face {
  font-family: 'Ubuntu-Medium'; /*reference name*/
  src: url('./fonts/Ubuntu-Medium.ttf'); /*URL to font*/
  font-display: block; /*briefly hide text until the custom font is loaded*/
}

@font-face {
  font-family: 'Ubuntu-Light'; /*reference name*/
  src: url('./fonts/Ubuntu-Light.ttf'); /*URL to font*/
  font-display: block; /*briefly hide text until the custom font is loaded*/
}

@font-face {
  font-family: 'Ubuntu-LightItalic'; /*reference name*/
  src: url('./fonts/Ubuntu-LightItalic.ttf'); /*URL to font*/
  font-display: block; /*briefly hide text until the custom font is loaded*/
}

@font-face {
  font-family: 'Ubuntu-MediumItalic'; /*reference name*/
  src: url('./fonts/Ubuntu-MediumItalic.ttf'); /*URL to font*/
  font-display: block; /*briefly hide text until the custom font is loaded*/
}
