.processing-notification-container {
  display: flex;
  flex-direction: column;
  min-height: 320px;
}

.processing-header {
  margin-top: 30px;
  margin-left: 20px;
  font: normal normal normal 26px Ubuntu;
  letter-spacing: 0px;
  line-height: 30px;
  color: #1b1b1b;
}

.processing-message {
  margin: 20px;
  font: normal normal normal 13px Ubuntu-Light;
  letter-spacing: 0px;
  color: #1b1b1b;
  padding: 20px;
  background-color: #e8f5de;
  line-height: 24px;
}

.processing-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid #f4f4f4;
  padding: 10px 20px 10px 0px;
}

.processing-button {
  width: 100px;
  height: 25px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font: normal normal normal 13px Ubuntu-Medium;
  color: #1b1b1b;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 6px #0000001a;
}
