.modal-container {
  width: 720px;
  height: 506px;
  max-height: 506px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  font-family: Ubuntu-Medium;
}

.modal-slim-header {
  align-items: center;
  height: 30px;
  background-color: #3f86a8;
  padding: 10px 20px;
  border-radius: 3px 3px 0 0;
  color: #fff;
  justify-content: space-between;
  display: flex;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 14px;
}

.modal-body {
  flex-grow: 1;
  padding: 10px 20px;
  overflow-y: auto;
}

.modal-item-card-container {
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  margin: 10px 0;
  color: #1b1b1b;
}

.modal-item-card {
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-item-card-row {
  display: flex;
  font-size: 13px;
  line-height: 24px;

  span {
    width: 110px;
    margin-right: 20px;
    color: #1b1b1b;
  }

  div {
    font-family: Ubuntu-Light;
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-style: solid;
  border-top-color: #d6d6d6;
  border-top-width: 1px;
  padding: 0 25px;
  box-sizing: border-box;
}

.modal-slim-footer-button {
  margin: 10px 0;
}
