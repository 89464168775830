$hv-bg-grey: #f4f4f4;

$hv-bg-white-light: #ffffff;
$hv-bg-white-dark: #f1f1f1;

$hv-bg-green-light: #72b456;
$hv-bg-green-dark: #578c41;
$hv-bg-blue-dark: #144a65;

$hv-bg-orange-light: #fe7e00;
$hv-bg-orange-dark: #bf6f1f;

$hv-bg-white-grad: linear-gradient(180deg, $hv-bg-white-light 0%, $hv-bg-white-dark 100%);
$hv-bg-green-grad: linear-gradient(180deg, $hv-bg-green-light 0%, $hv-bg-green-dark 100%);
$hv-bg-orange-grad: linear-gradient(180deg, $hv-bg-orange-light 0%, $hv-bg-orange-dark 100%);

$hv-fill-light-grey: #d6d6d6;

$lab: rgb(146, 94, 177);
$imaging: rgb(214, 87, 70);
$hospital: rgb(69, 104, 229);
$medicalclaims: rgb(81, 151, 213);
$rxclaims: rgb(71, 191, 96);
$device: rgb(214, 87, 69);
$emr: rgb(216, 131, 59);
$transcription: rgb(44, 153, 154);
$biospecimen: rgb(129, 129, 129);
$consumer: rgb(44, 155, 154);
$privatedata: rgb(20, 74, 101);
