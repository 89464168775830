@import '../../styles/variables';

.add-dataset-container {
  width: 960px;
  background: #fff;
  border: 2px solid #e3e3e3;
  border-radius: 3px;
  margin: 60px auto 0;
}

.add-data-container-header {
  height: 50px;
  background: $hv-bg-blue-dark 0% 0% no-repeat padding-box;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font: normal normal normal 26px/30px Ubuntu;
  letter-spacing: 0px;
  color: #ffffff;
}

.add-data-progress-section {
  border-bottom: 1px solid #e3e3e3;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}

.add-data-line {
  width: 780px;
  height: 0px;
  border: 1px solid #81b261;
  position: absolute;
  margin-top: 16px; // 15px for half-way point + 1px for border
}

.add-data-progress-box-container {
  z-index: 10;
  font: normal normal normal 13px/14px Ubuntu-Medium;
  letter-spacing: 0px;
  color: #1b1b1b;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.add-data-progress-box {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #81b261;
  font: normal normal normal 18px/21px Ubuntu-Medium;
  letter-spacing: 0px;
  color: #81b261;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 125px;
  margin-right: 125px;
  margin-bottom: 10px;
}

.box-active {
  background: #99d276 0% 0% no-repeat padding-box;
  border: 2px solid #72b456;
  color: #ffffff;
}

.box-complete {
  background: #72b456 0% 0% no-repeat padding-box;
  border: 2px solid #72b456;
}

.add-new-data-footer {
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
  height: 45px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.footer-page-1 {
  justify-content: flex-end;
}
