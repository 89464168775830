.identifiable-modal-checkbox {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Ubuntu-Medium';
  font-size: 13px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }

  input[type='checkbox'] {
    margin-left: 0px;
    margin-right: 10px;
    cursor: pointer;
  }
}
