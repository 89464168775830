.provide-data-information-container {
  display: flex;
  flex-direction: column;
}

.data-mapping-section {
  padding: 20px 0px 30px;
  border-bottom: 1px solid #e3e3e3;
}

.data-mapping-form {
  margin: 0px 20px;
  h1 {
    margin-bottom: 20px;
  }
}

.data-index-table-container {
  margin-top: 30px;

  table,
  th,
  td {
    border: 1px solid #e3e3e3;
    padding: 10px;
  }
}

.data-index-table {
  font-family: Ubuntu-Light;
  font-size: 13px;
  color: #1b1b1b;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.data-index-table-headers {
  font-family: Ubuntu-Medium;
  text-align: left;

  th:first-child {
    background-color: #e8eaed;
  }

  th {
    background-color: #f8f9fa;
  }
}

.data-index-table td {
  height: 45px;
}

.data-index-table-input-container {
  display: flex;
}

.data-index-table-input-container > .hv-dropdown-container {
  width: 375px;
}

p.autodetected {
  margin: auto 10px;
  font-style: italic;
}

.data-index-table-input {
  width: 100%;
  height: 20px;
  background-color: white;
}

.data-index-toggle-container {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-switch {
  position: relative;
  width: 55px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 13px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: 'Yes';
      padding-left: 8px;
      background-color: #72b456;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: 'No';
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 18px;
    margin-right: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.data-description-section {
  padding: 30px 20px;
  h1 {
    margin-bottom: 20px;
  }
}

.data-description-form {
  display: flex;
  flex-direction: column;
  label {
    margin-top: 26px;
  }
  .input-field {
    margin-top: 5px;
    width: 225px;
    height: 35px;
    box-sizing: border-box;
  }
}

.data-description-input-field {
  width: 225px;
  height: 35px;
  margin-top: 5px;
}

.label-with-additional-margin {
  margin-top: 26px;
}

.label-with-additional-text {
  span {
    margin-left: 5px;
    font: italic normal normal 13px/14px Ubuntu-Light;
    letter-spacing: 0px;
  }
}

.description-box {
  margin-top: 5px;
  width: 920px;
  height: 98px;
  resize: vertical;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 10px;
  font: normal normal normal 13px/24px Ubuntu-Light;
  letter-spacing: 0px;
  color: #1b1b1b;
  outline: none;
  box-sizing: border-box;
}
