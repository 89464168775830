.nav-container {
  width: 100%;
  height: 40px;
  position: fixed;
  z-index: 1030;

  box-shadow: 0px 0px 6px #0000001a;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.nav-section {
  flex-grow: 1;
  padding: 10px;
  flex-basis: 0;
}

.nav-center {
  text-align: center;
}

.nav-right {
  text-align: right;
}

.logout-button {
  float: right;
}
