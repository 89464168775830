.toggle-container {
  font-size: 13px;
  font-family: Ubuntu-Medium;
  width: 48px;
  height: 19.5px;
  color: #fff;
  border: 1px solid #939393;
  border-radius: 20px;
  padding: 2px;
  position: relative;
  background-color: #c7c7c7;
}

.toggle-container-active {
  @extend .toggle-container;
  background-color: #72b456;
  border: 1px solid #709828;
}

.dialog-button {
  background-color: #fff;
  padding: 9.5px 9.5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 9px;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px #00000029;
  position: absolute;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e2e2e2;
  }
}

.disabled {
  left: 3px;
}

.right {
  padding: 4px 4px 0px 4px;
  transition: all 0.3s ease;
  float: right;
  line-height: 12px;
}

.left {
  padding: 4px 2px 0px 4px;
  transition: all 0.3s ease;
  float: left;
  line-height: 12px;
}
