.configure-data-flow-data-source-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.configure-data-flow-data-source-section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e3e3e3;
}

.data-flow-source-section {
  margin: 0 20px;
}

.data-source-section-form {
  margin: 30px 20px;
  display: flex;
  flex-direction: column;

  input {
    margin-top: 5px;
    width: 460px;
    height: 35px;
    box-sizing: border-box;
  }
}

.data-destination-section-form {
  margin: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  input {
    margin-top: 5px;
    width: 460px;
    height: 35px;
  }
}

.data-flow-generic-dropdown-style {
  height: 35px;
  margin-top: 5px;
}

.data-destination-dropdown {
  @extend .data-flow-generic-dropdown-style;
  width: 460px;
}

.data-dropdown-row {
  @extend .data-flow-generic-dropdown-style;
  display: flex;
  flex-direction: row;

  .data-individual-dropdown {
    width: 225px;
    height: inherit;
    margin-right: 10px;
  }
}
