.center-modal {
  position: fixed;
  left: 50%;
  top: calc(max(50%, 400px));
  opacity: 1;
  background-color: white;
  z-index: 200;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.301);
}

.center-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 200;
  height: 100%;
  width: 100%;
  background-color: black;
  transition: opacity 0.3s;
}
