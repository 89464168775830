@import '../../styles/variables';

.name-pill-container {
  height: 25px;
  display: flex;
  width: fit-content;
}

.name-pill-text-container {
  display: flex;
  align-items: center;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #d6d6d6;
  border-radius: 0 5px 5px 0;
  background-color: #f1f1f1;
  z-index: 2;
}

.name-pill-text {
  padding: 0 5px;
  font-size: 13px;
  font-family: 'Ubuntu-Medium';
}

.name-pill-color {
  min-width: 8px;
  height: 100%;
  border-radius: 5px 0 0 5px;
}

.name-pill-lab {
  @extend .name-pill-color;
  background-color: $lab;
}

.name-pill-imaging {
  @extend .name-pill-color;
  background-color: $imaging;
}

.name-pill-hospital {
  @extend .name-pill-color;
  background-color: $hospital;
}

.name-pill-medicalclaims {
  @extend .name-pill-color;
  background-color: $medicalclaims;
}

.name-pill-rxclaims {
  @extend .name-pill-color;
  background-color: $rxclaims;
}

.name-pill-device {
  @extend .name-pill-color;
  background-color: $device;
}

.name-pill-emr {
  @extend .name-pill-color;
  background-color: $emr;
}

.name-pill-transcription {
  @extend .name-pill-color;
  background-color: $transcription;
}

.name-pill-biospecimen {
  @extend .name-pill-color;
  background-color: $biospecimen;
}

.name-pill-consumer {
  @extend .name-pill-color;
  background-color: $consumer;
}

.name-pill-privatedata {
  @extend .name-pill-color;
  background-color: $privatedata;
}

.name-pill-tooltip {
  position: relative;
  left: -50%;
  bottom: -100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 20;
  min-width: 100px;

  .tooltip-text {
    justify-content: center;
    align-items: center;
    padding: 5px 5px;

    font-family: 'Ubuntu-Medium';
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 12px #0000004d;
    border: 1px solid #d6d6d6;
  }

  .tooltip-text:after {
    content: '';
    position: absolute;
    left: 45%;
    top: -4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid white;
  }
}

.status-container {
  display: flex;
  align-items: center;
}

.status-text {
  margin-left: 3px;
}
