/* Button to add Hive partition condition */
.filter-btn {
  margin-top: 10px;
  width: 106px;
  height: 25px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
  box-shadow: 0px 0px 6px #0000001a;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-family: 'Ubuntu-Medium';
  font-size: 13px;
  color: #1b1b1b;
  cursor: pointer;

  &:hover {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
  }

  svg {
    margin: auto;
    height: 13px;
    margin-right: 5px;
  }
}

/* Styling for a single Hive partition condition row */
.hive-filter-container {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .input-field {
    width: 225px;
    height: 35px;
    box-sizing: border-box;
  }
  .margin-left {
    margin-left: 10px;
  }
  .filter-remove-button {
    height: 16px;
    .a {
      fill: #b5b5b5;
    }
    .b {
      fill: #fff;
    }
  }
}

/* Styling for an input row w/ valid icon*/
.input-icon-row {
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  .valid-icon {
    margin-top: 5px;
    height: 24px;
    .a {
      fill: #72b456;
    }
    .b {
      fill: #fff;
    }
  }
  .loading-icon {
    background-image: url('/images/load-graphic-small.gif');
    margin-left: 5px;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
  }
}

.file-deprecation-warning {
  margin: 10px 0px 30px 0px;
  padding: 10px;
  background: #e8f5de 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #1b1b1b;
  font: normal normal normal 13px/24px Ubuntu-Light;
  background-color: #f5ecec;
}
