@import '../../styles/variables';

.tabs-container {
  height: 25px;
  display: flex;
  flex-grow: 1;
  margin: 30px 20px 0 20px;
}

.tab-container {
  display: flex;
}

.tab {
  padding: 6px 20px 5px 20px;
  margin-bottom: -1px;
  margin-right: -2px;

  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;

  font-family: 'Ubuntu-Medium';
  color: #b0b0b0;
  border-color: #ffffff;
  border-bottom-color: #d6d6d6;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px 3px 0px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;

  &:hover {
    color: #1b1b1b;
    border-color: #d6d6d6;
    border-bottom-color: #ffffff;
    z-index: 160;
  }
}

.tab-active {
  @extend .tab;
  color: #1b1b1b;
  border-color: #d6d6d6;
  border-bottom-color: #ffffff;
  z-index: 150;
}

.tab-border-pixel {
  background-color: $hv-fill-light-grey;
  width: 1px;
  height: 1px;
  z-index: 170;
  top: 25px;
  left: 1px;
  position: relative;
}

.tab-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-search {
  @extend .tab;
  padding-right: 0;
}

.tab-search-active {
  @extend .tab-search;
  @extend .tab-active;
}

.tab-search-title {
  @extend .tab-title;
  padding-left: 20px;
}

.tab-search-exit-container {
  margin-left: 10px;
  border-top-right-radius: 2px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: grey;

    svg {
      fill: white !important;
    }
  }
}

.tab-search-exit-svg {
  width: 8px;
  height: 8px;
  fill: #b2b2b2;
}
