.autoscan-modal-notification-box {
  background: #e8f5de 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin: 20px;
  padding: 20px;
  text-align: left;
  font-family: 'Ubuntu-Light';
  font-size: 13px;
  color: #1b1b1b;
  line-height: 24px;
}
