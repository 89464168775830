.options-container {
  display: flex;
}

.option-container {
  margin: 0px 5px;
  height: 25px;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 14px;
  border: 1px solid #d6d6d6;
  padding: 0px 8px 0px 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #d6d6d6 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.option-icon-container {
  margin-right: 5px;
}

.option-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-text {
  font: normal normal normal 13px/14px Ubuntu-Medium;
}
