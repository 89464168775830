.table-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.table-ref {
  overflow-y: auto;
  margin: 10px 0 20px 20px;
  padding-right: 20px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
}

.table-header {
  font-family: 'Ubuntu';

  text-align: left;
  padding: 0.75em 0.75em 0.75em 0;
  vertical-align: bottom;
  white-space: nowrap;

  background-color: #3f86a8;
  color: #ffffff;

  position: sticky;
  top: 0;
  z-index: 3;

  &:first-child {
    padding-left: 2em;
  }
}

.table-row {
  font-family: 'Ubuntu-Light';
  color: #1b1b1b;
  border-top: 1px solid #d6d6d6;

  &:hover {
    td {
      background-color: #f4f4f4;
    }
  }

  td {
    padding: 0.5em 0.75em 0.5em 0;
    max-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: inherit;

    &:first-child {
      padding-left: 10px;
      overflow: visible;
    }

    // &:last-child {
    //   padding-right: 2em;
    // }
  }
}

.no-results {
  display: flex;
  font-family: 'Ubuntu-Light';
  font-style: italic;
  color: #909090;
  margin: 50px 0px 100px;
  justify-content: center;
  align-items: center;
}

.no-search-results {
  margin: 40px;
  color: #1b1b1b;

  h2 {
    font-family: 'Ubuntu';
    margin: 0px 0px 5px 0px;
  }

  p {
    font-family: 'Ubuntu-Light';
    font-size: 16px;
  }

  li {
    font-family: 'Ubuntu-Light';
  }

  span {
    font-family: 'Ubuntu-Medium';
  }
}

.table-loading {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/load-graphic-small.gif');
  background-size: 50px 50px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
}

.table-first-row {
  display: flex;
  align-items: center;
}

.table-first-row-checkbox {
  padding-right: 5px;
}
