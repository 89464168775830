.search-form {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 18px;
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
}

.search-form:hover {
  border-color: black;
}

.search-form-focused {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid black;
  border-radius: 18px;
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 1px 1px black;
}

.search-input {
  background: inherit;
  border: none;
  margin: 0px 10px 0px 10px;
  color: black;
  width: 250px;
  cursor: pointer;
}

.search-input:hover {
  outline: none;
}

.search-input::placeholder {
  color: #b0b0b0 !important;
}

.search-input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #72b456;
  font-size: 16px;
  margin-left: 8px;
}
