@import '../../styles/variables';

.dataset-table-tabs {
  display: flex;
  border-bottom: 1px solid #d6d6d6;
}

.dataset-table-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.dataset-table-action-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.dataset-table-action-btn {
  display: flex;
  height: 25px;
  cursor: pointer;

  &:hover {
    .dataset-table-action-text {
      background: $hv-bg-white-dark;
    }

    .dataset-table-action-icon {
      background: $hv-bg-green-dark;
    }
  }
}

.dataset-table-action-btn-orange {
  @extend .dataset-table-action-btn;

  &:hover {
    .dataset-table-action-icon {
      background: $hv-bg-orange-dark;
    }
  }
}

.dataset-table-action-icon {
  background: $hv-bg-green-grad;
  display: flex;
  align-items: center;
  padding: 0 5px;

  border: 1px solid $hv-bg-green-grad;
  border-radius: 3px 0 0 3px;
}

.dataset-table-action-icon-orange {
  @extend .dataset-table-action-icon;
  background: $hv-bg-orange-grad;
}

.dataset-table-action-text {
  background: $hv-bg-white-grad;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border: 1px solid $hv-fill-light-grey;
  border-radius: 0 3px 3px 0;
  border-left: none;
  font: normal normal normal 13px/14px Ubuntu-Medium;
  letter-spacing: -0.26px;
  color: #1b1b1b;
  text-decoration: none;
}

.dataset-table-extras-container {
  display: flex;
  margin: 15px 20px 0 20px;
  height: 27px;
}

.dataset-update-status-confirm-btn {
  background: transparent linear-gradient(180deg, #ca6745 0%, #9f5137 100%) 0% 0% no-repeat
    padding-box !important;
}

.dataset-update-status-modal-body-ctn {
  font: normal normal normal 13px/24px Ubuntu-Light;
  border-radius: 3px;
  padding: 20px;
  margin: 20px 20px 20px 0px;
  width: 600px;
}
