.select-data-permissions-container {
  display: flex;
  flex-direction: column;
}

.select-data-permissions-section {
  padding: 30px 0px;
}

.select-data-permissions-form {
  padding: 0px 20px;
}

.select-data-permission-input-section {
  margin-top: 30px;

  .select-data-permission-input-dropdown {
    min-height: 35px;
    margin-top: 5px;
  }
}
