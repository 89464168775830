.generic-modal {
  min-width: 520px;
}

.generic-wide-modal {
  width: 720px;
}

.generic-modal-header {
  border-bottom: 1px #d6d6d6 solid;
  color: #1b1b1b;

  h1 {
    font-size: 26px;
    margin: 0;
    padding: 15px 20px;
  }
}

.generic-modal-info-box {
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  background-color: #e8f5de;
  p {
    color: #1b1b1b;
  }
  .bold-text {
    font-family: 'Ubuntu-Medium';
  }
  .light-text {
    font-family: 'Ubuntu-Light';
  }
}

.generic-modal-footer {
  text-align: right;
  padding-right: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.generic-modal-footer-border {
  border-top: 1px solid #d6d6d6;
}

.generic-modal-dismiss-btn {
  width: fit-content;
  min-width: 100px;
  height: 25px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 6px #0000001a;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-family: 'Ubuntu-Medium';
  font-size: 13px;
  color: #1b1b1b;
  cursor: pointer;

  &:hover {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
  }
}

.generic-modal-confirm-btn {
  width: fit-content;
  min-width: 100px;
  padding: 0 20px 0 20px;
  height: 25px;
  background: transparent linear-gradient(180deg, #72b456 0%, #578c41 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 3px;
  border: 0px transparent;
  font-family: 'Ubuntu-Medium';
  font-size: 13px;
  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background: transparent linear-gradient(180deg, #578c41 0%, #578c41 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 0px 6px #00000033;
    border-radius: 3px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.generic-modal-extra-button-bottom-left {
  float: left;
  margin-left: 20px;
  cursor: pointer;
}

.generic-modal-body {
  margin-left: 20px;
}

.generic-modal-instructions-box {
  background: #e8f5de 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  text-align: left;
  font-family: 'Ubuntu-Light';
  font-size: 13px;
  color: #1b1b1b;
  line-height: 24px;
}
