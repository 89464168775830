.table-heading {
  background: #144a65 0% 0% no-repeat padding-box;
  border-radius: 3px 3px 0px 0px;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.table-heading h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0px;
  font-size: 26px;
  font-weight: normal;
}
