.new-data-summary-container {
  display: flex;
  flex-direction: column;
}

.new-data-summary-section {
  padding: 30px 20px;
}

.new-data-summary-table-container {
  margin-top: 30px;
}

.new-data-summary-table {
  width: 100%;
  border-spacing: 0;
  cursor: pointer;

  tr {
    height: 35px;
    background: #ebebeb 0% 0% no-repeat padding-box;
  }
  tr:nth-child(even) {
    background: #f7f7f7 0% 0% no-repeat padding-box;
  }
  tr:hover {
    outline: 1px solid #909090;
  }
  tr:hover .summary-table-edit-cell {
    color: #0eaab4;
    &:hover {
      color: #04595f;
    }
  }

  .summary-table-label-column {
    width: 25%;
  }
  .summary-table-value-column {
    width: 70%;
  }
  .summary-table-edit-column {
    width: 5%;
  }
  .summary-table-label-cell {
    font: normal normal normal 13px/35px Ubuntu-Medium;
    letter-spacing: 0px;
    color: #1b1b1b;
    padding-left: 20px;
  }
  .summary-table-value-cell {
    font: normal normal normal 13px/35px Ubuntu-Light;
    letter-spacing: 0px;
    color: #1b1b1b;
  }
  .summary-table-edit-cell {
    text-decoration: underline;
    font: italic normal normal 13px/14px Ubuntu-Light;
    letter-spacing: 0px;
    color: transparent;
  }

  .summary-table-error-span {
    font: italic normal normal 13px/14px Ubuntu-Medium;
    margin-left: 15px;
    color: #ca6745;
  }

  .name-email-display-box {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
