@import '../styles/variables';

h1 {
  font: normal normal normal 26px/30px Ubuntu;
  letter-spacing: 0px;
  margin: 0 0 10px 0;
}

label {
  font: normal normal normal 13px/14px Ubuntu-Medium;
  letter-spacing: 0px;
  color: #1b1b1b;
}

button {
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

input,
textarea {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding-left: 10px;
  font: normal normal normal 13px/14px Ubuntu-Light;
  letter-spacing: -0.26px;
  color: #1b1b1b;

  &::placeholder {
    font: italic normal normal 13px/14px Ubuntu-Medium;
    letter-spacing: -0.26px;
    color: #c4c4c4;
  }

  &:focus {
    outline: 0;
  }
}

input[type='checkbox'] {
  cursor: pointer;
}

.input-states {
  // Default: When input is not focused, show this light gray border
  &:not(:focus) {
    border: 1px solid #d6d6d6;
  }

  // Active: When input is focused (typing), show dark gray border and a box-shadow
  &:focus {
    border: 1px solid #a0a0a0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  // Hover: When input is hovered, show dark gray border (without a box-shadow)
  &:hover {
    border: 1px solid #a0a0a0;
    cursor: pointer;
  }

  // Filled: When input is filled, show the default light gray border and white background
  &:not(:placeholder-shown) {
    background: #ffffff 0% 0% no-repeat padding-box;
  }
}

.app-container {
  background-color: $hv-bg-grey;
  overflow: auto;
  height: 100vh;
}

.contents {
  background: #fff;
  border: 2px solid #e3e3e3;
  border-radius: 3px;
  margin: 60px 80px 0;
  min-width: 1280px;
  min-height: 400px;
}

.auth-message {
  padding: 50px;
}

a {
  text-decoration: underline;
  font: italic normal normal 13px/14px 'Ubuntu-Light';
  letter-spacing: 0px;
  color: #0eaab4;
  opacity: 1;
}

.info-box {
  background: #e8f5de 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal normal 13px/24px Ubuntu-Light;
  letter-spacing: 0px;
  color: #1b1b1b;
  display: flex;
  align-items: center;
  line-height: 24px;
  padding: 15px 20px;
}

.hv-dropdown-container {
  width: inherit;
  height: inherit;

  .hv-dropdown__control {
    width: inherit;
    height: inherit;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    cursor: pointer;

    &--is-focused {
      box-shadow: 0px 0px 6px #00000033;
      border: 1px solid #a0a0a0;
    }

    &:hover {
      border: 1px solid #a0a0a0;
    }
  }

  .hv-dropdown__option {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 25px;
    font: normal normal normal 13px/30px Ubuntu-Light;
    letter-spacing: 0px;
    color: #1b1b1b;
    display: flex;
    align-items: center;
    border: none;

    &:hover {
      cursor: pointer;
      background: #f4f4f4 0% 0% no-repeat padding-box;
    }
  }

  .hv-dropdown__value-container {
    width: inherit;
    height: inherit;
    font: normal normal normal 13px/14px Ubuntu-Light;
    letter-spacing: 0px;
    color: #1b1b1b;

    .hv-dropdown__multi-value {
      background: #f1f1f1 0% 0% no-repeat padding-box;
      border: 1px solid #d6d6d6;
      border-radius: 3px;

      .hv-dropdown__multi-value__label {
        font: normal normal normal 13px/14px Ubuntu-Medium;
        letter-spacing: 0px;
        color: #1b1b1b;
      }
      .hv-dropdown__multi-value__remove {
        margin-left: 10px;

        svg {
          fill: #b2b2b2;
        }
        &:hover {
          background: #b2b2b2;
          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

.generic-confirm-btn {
  width: fit-content;
  min-width: 100px;
  padding: 0 20px 0 20px;
  height: 25px;
  background: transparent linear-gradient(180deg, #72b456 0%, #578c41 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.33);
  border-radius: 3px;
  border: 0px transparent;
  font: normal normal normal 13px/14px Ubuntu-Medium;
  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background: transparent linear-gradient(180deg, #578c41 0%, #578c41 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.33);
    border-radius: 3px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.generic-dismiss-btn {
  width: fit-content;
  min-width: 100px;
  height: 25px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 0px 6px #0000001a;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-family: 'Ubuntu-Medium';
  font-size: 13px;
  color: #1b1b1b;
  cursor: pointer;

  &:hover {
    background: #f4f4f4 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.form-validation-error {
  font: italic normal normal 13px/14px Ubuntu-Medium;
  letter-spacing: 0px;
  color: #ca6745;
  margin-top: 5px;
}

.loading {
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-image: url('/images/load-graphic-large.gif');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 80px;
  height: 200px;
}
